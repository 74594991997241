<template>
    <v-navigation-drawer
        v-model="drawer"          
        width="400"
        app
        temporary
        right
        class="wost-drawer"
    >
        <div class="navigation-head">
            <span>{{ titleForm }}</span>                         
        </div>

        <div class="pl-3 pt-3 pr-3" style="height: 80vh;">                
            <template>
                <v-row>
                    <v-col cols="12">
                        <v-autocomplete
                            v-model="filters.office_id"
                            :items="offices"
                            label="Seleccione sucursal"
                            hide-details
                            outlined
                            :item-text="customText"
                            item-value="id"
                            clearable
                        ></v-autocomplete>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12">
                        <v-text-field v-model="filters.name" label="Nombre" outlined hide-details></v-text-field>
                    </v-col>
                </v-row>                 
            </template>                    
        </div>

        <div class="mt-3 pl-3 pt-3 pr-3">
            <template>
                <v-row>
                    <v-col cols="6">
                        <v-btn block height="40" dark color="red" @click="handleClearFilters">
                            <v-icon size="16" class="mr-1">far fa-trash-alt</v-icon>
                            Quitar filtros
                        </v-btn>
                    </v-col>
                    <v-col cols="6">
                        <v-btn dark color="blue" block height="40" @click="handleSearch">
                            <v-icon size="16" class="mr-1">fas fa-search</v-icon>
                            Filtrar datos
                        </v-btn>
                    </v-col>
                </v-row>
            </template>            
        </div>

    </v-navigation-drawer>
</template>

<script>
import {mapState, mapActions} from 'vuex';

export default {
    name: 'Filters',
    data() {
        return {
            drawer: false,
            titleForm: null,
            filters: {
                name: null,
                sede_id: null,
                office_id: null
            }
        }
    },
    computed: {
        ...mapState('sedes', [
            'sedes'
        ]),
        ...mapState('offices', [
            'offices'
        ])
    },
    methods: {
        ...mapActions('sedes', [
            'getSedes'
        ]),
        ...mapActions('offices', [
            'getOffices',
            'run_set_offices'
        ]),
        ...mapActions('tills', [
            'getTills'
        ]),
        showForm(title) {
            this.titleForm = title;
            this.drawer = !this.drawer;
        },
        customText(item){
            let text = item.name;
            
            return text;
        },
        closeForm() {
            this.drawer = false;
        },
        clearData() {
            this.titleForm = null;
            this.filters.name = null;
            this.filters.sede_id = null;
            this.filters.office_id = null;
        },
        async handleClearFilters() {
            this.clearData()
            await this.getTills(this.filters)
        },
        async handleSearch() {
            await this.getTills(this.filters)
            this.closeForm()
        }
    }
}
</script>
