<template>
    <v-navigation-drawer
        v-model="drawer"          
        width="400"
        app
        temporary
        right
        class="wost-drawer"
    >
        <div class="navigation-head">
            <span>{{ titleForm }}</span>                         
        </div>
        <div class="pl-3 pt-3 pr-3" style="height: 80vh;">
            <template>
                <v-row>
                    <v-col cols="12">
                        <v-autocomplete
                            ref="office_id"
                            v-model="tillForm.office_id"
                            :items="offices"
                            label="Seleccione sucursal"
                            outlined
                            :item-text="customText"
                            item-value="id"
                            clearable
                            @change="handleSelectOffice"
                            :rules="[() => !!tillForm.office_id || 'Es necesario seleccionar una sucursal']"
                            hide-details="auto"
                            required
                        ></v-autocomplete>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12">
                        <v-text-field 
                            ref="name"
                            v-model="tillForm.name" 
                            label="Nombre" 
                            outlined
                            :rules="[() => !!tillForm.name || 'Es necesario ingresar un nombre']"
                            hide-details="auto"
                            required
                        ></v-text-field>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12">
                        <v-card>
                            <v-card-title class="pt-2 pb-2">
                                <span class="title-list-users">Usuarios</span>
                            </v-card-title>
                            <v-divider></v-divider>
                            <v-card-text class="users-list">
                                <v-treeview
                                    selectable
                                    selected-color="blue"
                                    :items="users"
                                    open-on-click
                                    v-model="tillForm.users"
                                ></v-treeview>
                            </v-card-text>
                        </v-card>
                    </v-col>
                </v-row>
            </template>
        </div>
        <div class="mt-2 pl-4 pt-2 pr-4">
            <template>
                <v-row>
                    <v-col cols="6" class="pt-3 pb-3">
                        <v-btn block height="40" dark color="red" @click="handleCancel">
                            <v-icon class="mr-1" size="16">far fa-times-circle</v-icon>
                            Cancelar
                        </v-btn>
                    </v-col>
                    <v-col cols="6" class="pt-3 pb-3">
                        <v-btn v-if="action == 'create'" dark color="success" block height="40" @click="handleStore">
                            <v-icon class="mr-1" size="16">far fa-save</v-icon>
                            Guardar
                        </v-btn>
                        <v-btn v-if="action == 'update'" dark color="success" block height="40" @click="handleUpdate">
                            <v-icon class="mr-1" size="16">far fa-save</v-icon>
                            Actualizar
                        </v-btn>
                    </v-col>
                </v-row>
            </template>
        </div>
    </v-navigation-drawer>
</template>

<script>
import {mapState, mapActions} from 'vuex';
export default {
    name: 'CreateUpdate',
    data() {
        return {
            drawer: false,
            titleForm: null,
            action: null,
            tillForm: {
                id: null,
                name: null,
                company_id: null,
                sede_id: null,
                office_id: null,
                users: []
            }
        }
    },
    computed: {
        ...mapState('authentication', [
            'company_id'
        ]),
        ...mapState('sedes', [
            'sedes'
        ]),
        ...mapState('offices', [
            'offices'
        ]),
        ...mapState('users', [
            'users'
        ])
    },
    methods: {
        ...mapActions('sedes', [
            'getSedes'
        ]),
        ...mapActions('offices', [
            'getOffices',
            'run_set_offices'
        ]),
        ...mapActions('users', [
            'getStaff',
            'run_set_users'
        ]),
        ...mapActions('tills', [
            'store',
            'update'
        ]),
        async showForm(title, action, till = null) {            
            await this.clearData();
            this.tillForm.company_id = this.company_id;
            this.run_set_users([]);
            this.titleForm = title
            this.action = action
            if (till) {                
                this.setTill(till);
            }
            this.drawer = !this.drawer;
        },
        async setTill(till){
            this.tillForm.id = till.id;
            this.tillForm.name = till.name;
            this.tillForm.sede_id = till.office.sede_id;
            this.tillForm.office_id = till.office_id;
            
            await this.handleSelectOffice(this.tillForm.office_id);
            if (till.user_codes) {
                this.tillForm.users = till.user_codes;
            } else {
                this.tillForm.users = [];
            }
        },
        clearData() {
            this.titleForm = null;
            this.action = null;
            this.tillForm.id = null;
            this.tillForm.name = null;
            this.tillForm.office_id = null;
            this.tillForm.sede_id = null;
            this.tillForm.users = [];
            this.clearErrors();
        },
        closeForm() {
            this.clearData();
            this.drawer = false;
        },
        customText(item){
            let text = item.name
            
            return text
        },
        handleCancel(){
            this.closeForm();
        },
        async handleStore(){
            if (!this.existErrors()) {
                await this.store(this.tillForm);
                this.closeForm();
            }            
        },
        async handleUpdate(){
            if (!this.existErrors()) {
                await this.update(this.tillForm)
                this.closeForm()
            }            
        },
        async handleSelectOffice(value) {
            if(value !== undefined && value !== null){
                let filters = {
                    office_id: value
                }
                await this.getStaff(filters);
            } else {
                this.run_set_users([]);
            }
        },
        existErrors(){
            let hasErrors = false;

            Object.keys(this.tillForm).forEach(f => {                
                if (this.$refs[f]) {
                    if (!this.tillForm[f]) hasErrors = true
                    this.$refs[f].validate(true);
                }                
            });

            return hasErrors;
        },
        clearErrors() {
            Object.keys(this.tillForm).forEach(f => {
                if (this.$refs[f]) this.$refs[f].reset()                
            });
        },
    }
}
</script>

<style scoped>
.users-list{
    height: 45vh;
    overflow-y: auto;
}

.title-list-users{
    font-size: 1rem !important;
    text-transform: uppercase;
    font-weight: 600;
}

.v-treeview >>> .v-treeview-node__level{
    width: 0px !important;
}
</style>